<template id="login-form">
    <div>
        <el-form
            id="register-form"
            ref="model"
            :model="model"
            label-position="top"
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <p class="login-description text-center">
                    To accept your invite to collaborate on this farm, please create your free Figured account.
                </p>
            </el-form-item>

            <el-form-item
                label="Email"
                :error="hasErrors('email')"
            >
                <el-input
                    id="email"
                    :value="userEmailParsed"
                    placeholder="Email"
                    type="email"
                    name="email"
                    class="disabled-email"
                    :disabled="true"
                />
            </el-form-item>

            <el-form-item
                label="Name"
                :error="hasErrors('name')"
            >
                <el-input
                    id="name"
                    v-model="model.name"
                    placeholder="Name"
                    name="name"
                />
            </el-form-item>

            <el-form-item
                label="Password"
                :error="hasErrors('password')"
            >
                <password-reveal-input @input="updatePassword" />
            </el-form-item>

            <el-form-item
                label="Role"
                :error="hasErrors('role')"
            >
                <el-select
                    id="role"
                    v-model="model.role"
                    placeholder="Select your role"
                    filterable
                >
                    <el-option
                        v-for="(roleKey, roleValue) in rolesParsed"
                        :key="roleValue"
                        :label="roleKey"
                        :value="roleValue"
                    />
                </el-select>
            </el-form-item>

            <el-form-item
                label="Timezone"
                :error="hasErrors('timezone')"
            >
                <el-select
                    id="timezone-select"
                    v-model="model.timezone"
                    placeholder="Select your timezone"
                    filterable
                >
                    <el-option-group
                        v-for="(options, group) in timezonesParsed"
                        :key="group"
                        class="timezone"
                        :label="group"
                    >
                        <el-option
                            v-for="(timezoneText, timezoneValue) in options"
                            :key="timezoneValue"
                            :label="timezoneText"
                            :value="timezoneValue"
                        />
                    </el-option-group>
                </el-select>
            </el-form-item>

            <el-form-item :error="hasErrors('region')">
                <el-select
                    v-if="isRegionSpecificZone"
                    id="region-select"
                    v-model="model.region"
                    placeholder="Select your region"
                    filterable
                >
                    <el-option
                        v-for="(regionLabel, regionValue) in regionsParsed"
                        :key="regionValue"
                        :label="regionLabel"
                        :value="regionValue"
                    />
                </el-select>
            </el-form-item>

            <div :class="{'has-error': showError('terms'), 'register-terms': true, 'is-error': true}">
                <el-checkbox
                    v-model="model.terms"
                    class="control-label"
                    :error="hasErrors('terms')"
                >
                    I agree to the <a
                        :href="lang.trans('external_urls.web_terms_and_conditions')"
                        target="_blank"
                    >Terms of Use</a>
                </el-checkbox>
                <div
                    v-if="showError('terms')"
                    class="has-error help-block"
                >
                    {{ hasErrors('terms') }}
                </div>
            </div>

            <el-form-item>
                <el-button
                    class="full-width"
                    type="success"
                    name="Register"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Register
                </el-button>
            </el-form-item>
        </el-form>

        <invisible-captcha
            ref="captcha"
            :public-key="publicKey"
            :show-captcha="parseJson(showCaptcha)"
            :has-error="hasCaptchaError"
            @verified="onCaptchaVerified"
            @closed="onCaptchaClosed"
        />
    </div>
</template>

<script>
import OrganisationRegister from "Assets/Modules/models/OrganisationRegister";
import PasswordRevealInput from "./PasswordRevealInput.vue";
import {routes, http} from "Figured/Assets/Modules";
import InvisibleCaptcha from "./InvisibleCaptcha";

export default {

    components: {
        InvisibleCaptcha,
        PasswordRevealInput
    },

    props: {
        roles: {
            type: String,
            required: true
        },
        timezones: {
            type: String,
            required: true
        },
        regions: {
            type: String,
            required: true
        },
        userEmail: {
            type: String,
            required: true
        },
        publicKey: {
            type: String,
            required: true
        },
        showCaptcha: {
            type: String,
            required: true
        },
        inviteHash: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            model: new OrganisationRegister(),
            formErrors: {},
            isLoading: false,
            regionSpecificZone: ["Pacific/Auckland"],
            hasCaptchaError: false,
        }
    },

    computed: {
        isRegionSpecificZone() {
            return _.includes(this.regionSpecificZone, this.model.timezone);
        },

        timezonesParsed() {
            return this.parseJson(this.timezones);
        },

        regionsParsed() {
            return this.parseJson(this.regions);
        },

        rolesParsed() {
            return this.parseJson(this.roles);
        },

        userEmailParsed() {
            return this.parseJson(this.userEmail);
        }
    },

    methods: {
        parseJson(json) {
            return JSON.parse(json);
        },

        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        hasErrors: function (inputName) {
            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onSubmit() {
            // Start Captcha verification
            this.$refs.captcha.start();
            this.isLoading = true;
        },

        updatePassword(password) {
            this.model.password = password;
        },

        onCaptchaVerified(captchaToken) {
            const registerRoute     = routes.get("registration_invite", {"inviteHash": this.inviteHash});
            this.model.captchaToken = captchaToken;

            http.post(registerRoute, this.model)
                .then(response => {
                    if (response.status === 200 && response.data[0] === "success") {
                        location.replace("/");
                        return;
                    }

                    this.hasCaptchaError = false;
                    this.isLoading       = false;
                })
                .catch((error) => {
                    if (Object.prototype.hasOwnProperty.call(error.response.data, "captchaToken")) {
                        this.hasCaptchaError = true;
                    } else {
                        this.hasCaptchaError = false;
                    }

                    this.formErrors = error.response.data;
                    this.isLoading = false;
                });
        },

        onCaptchaClosed() {
            this.isLoading = false;
        }
    },

    template: "#register-people-organisation",
};
</script>
