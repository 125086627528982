<template>
    <div>
        <div>
            <p class="sso-text">
                We could not find an existing user linked to this login. If you have an existing Figured user, please login below and we will link these accounts for future logins. If you are new to Figured click register to sign up.
            </p>
        </div>

        <div>
            <a :href="loginUrl">
                <el-button
                    class="full-width sso-button"
                    type="primary"
                >
                    Log in with an existing user
                </el-button>
            </a>
        </div>

        <div>
            <a :href="registrationUrl">
                <el-button
                    class="full-width sso-button"
                    type="primary"
                >
                    Register a new user
                </el-button>
            </a>
        </div>
    </div>
</template>

<script>
import {routes} from "Assets/Modules";

export default {
    name: "XeroSsoForm",

    props: {
        ssoSignupKey: {
            required: true,
            type: String,
        }
    },

    computed: {

        loginUrl() {
            return routes.get("login.get",{}, {"sso_signup_key": this.ssoSignupKey});
        },

        registrationUrl() {
            return routes.get("registration.get",{}, {"sso_signup_key": this.ssoSignupKey});
        }
    }
}
</script>

<style lang="scss">
@import 'src/Figured/Assets/Themes/default/_colours.scss';

    .sso-button {
        margin-top: 15px;
    }

    .sso-text {
        line-height: 25px;
    }

</style>