import LoginForm from "./Components/LoginForm.vue";
import RegisterForm from "./Components/RegisterForm.vue";
import ForgotPassword from "./Components/ForgotPassword.vue";
import ResetPassword from "./Components/ResetPassword.vue";
import twofaCheck from "./Components/twofaCheck.vue";
import twofaBackup from "./Components/twofaBackup.vue";
import InviteLogin from "./Components/InviteLogin.vue";
import registerPeopleOrganisation from "./Components/registerPeopleOrganisation.vue";
import registerInvite from "./Components/registerInvite.vue";
import practiceSelect from "./Components/practiceSelect.vue";
import XeroSsoForm from "Assets/Components/XeroSsoForm";

const vue = new Vue({
    el: "#login",
    components: {
        LoginForm,
        RegisterForm,
        ForgotPassword,
        ResetPassword,
        twofaCheck,
        twofaBackup,
        registerPeopleOrganisation,
        InviteLogin,
        registerInvite,
        practiceSelect,
        XeroSsoForm,
    },
});
