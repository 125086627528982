<template>
    <div>
        <el-form
            id="register-form"
            ref="model"
            :model="model"
            label-position="top"
            @submit.native.prevent
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <p class="login-description text-center">
                    Two-Factor Authentication is enabled for your account. Enter the code from your app here.
                </p>
            </el-form-item>

            <el-form-item :error="hasErrors('code_verification')">
                <el-input
                    id="code_verification"
                    v-model="model.code_verification"
                    placeholder="Authentication Code"
                    name="code_verification"
                    autofocus
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    class="full-width padding"
                    type="primary"
                    name="Login"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Confirm
                </el-button>
            </el-form-item>

            <el-form-item>
                <a
                    :href="cancelUrl"
                    class="btn btn-link back-to-login-link"
                ><i class="el-icon-arrow-left" /> Cancel & return to Login</a>
            </el-form-item>

            <el-form-item>
                <div class="font-italic text-center">
                    Don't have your device?
                </div>
                <div class="back-to-login-link other-method-link text-center">
                    <a :href="backupUrl">
                        Use another authentication method
                    </a>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {routes, http} from "Figured/Assets/Modules";
import Twofacheck from "Figured/Assets/Modules/models/Twofacheck";

export default {
    template: "#twofa-check",

    data() {
        return {
            isLoading: false,
            model: new Twofacheck(),
            formErrors: {},
        }
    },

    props: {
        ssoKey: {
            type: String,
            required: false,
        }
    },

    computed: {
        cancelUrl() {
            return routes.get("two_factor_authentication.cancel");
        },

        backupUrl() {
            return routes.get("two_factor_authentication.backup_check.show")
        }
    },

    mounted() {
        if (this.ssoKey) {
            this.model.sso_signup_key = this.ssoKey;
        }
    },

    methods: {
        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        hasErrors: function (inputName) {
            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onSubmit() {
            const twofaRoute = routes.get("two_factor_authentication.check.post");
            this.isLoading = true;

            http.post(twofaRoute, this.model)
                .then(response => {
                    if (response.status === 200) {
                        location.replace(response.data.location);
                        return;
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    this.formErrors = error.response.data;
                    this.isLoading = false;
                });
        },
    },

}
</script>