<template id="login-form">
    <div>
        <el-form
            id="register-form"
            ref="model"
            :model="registerModel"
            label-position="top"
            @submit="onSubmit(registerModel.email, regularSignUp)"
            @submit.native.prevent
            @keyup.enter.native="onSubmit(registerModel.email, regularSignUp)"
        >
            <!-- All these sections will be rendered upon state behaviour changes
             depending on the data we have on the user trying to register -->

            <!-- EMAIL_SIGNUP_ONLY: Section only rendered at first when requesting for a valid email -->
            <template
                v-if="authStep === steps.STEP_EMAIL"
            >
                <p style="text-align: center; text-justify: distribute-all-lines">
                    Welcome to Figured!
                </p>
                <p style="text-align: center; text-justify: distribute-all-lines">
                    Enter your email to begin registration
                </p>

                <el-form-item
                    label="Email"
                    :error="hasErrors('email')"
                >
                    <el-input
                        id="email"
                        v-model="registerModel.email"
                        placeholder="Email"
                        type="email"
                        name="email"
                    />
                </el-form-item>

                <el-form-item ref="step-email-btn-next">
                    <el-button
                        class="full-width"
                        type="primary"
                        :disabled="!validEmail"
                        name="Register"
                        :loading="isLoading"
                        @click="next(registerModel.email)"
                    >
                        Next
                    </el-button>
                </el-form-item>
            </template>

            <!-- REGULAR_SIGNUP Only: Section only rendered when a regular/normal signup form is required -->
            <template
                v-else-if="authStep === steps.STEP_REGULAR_AUTH"
            >
                <el-form-item
                    label="Name"
                    :error="hasErrors('name')"
                >
                    <el-input
                        id="name"
                        v-model="registerModel.name"
                        placeholder="Name"
                        name="name"
                    />
                </el-form-item>

                <el-form-item
                    label="Email"
                    :error="hasErrors('email')"
                >
                    <el-input
                        id="email"
                        v-model="registerModel.email"
                        placeholder="Email"
                        type="email"
                        disabled="disabled"
                        name="email"
                    />
                </el-form-item>

                <el-form-item
                    label="Password"
                    :error="hasErrors('password')"
                >
                    <password-reveal-input @input="updatePassword" />
                </el-form-item>


                <el-form-item
                    label="Role"
                    :error="hasErrors('role')"
                >
                    <el-select
                        id="role"
                        v-model="registerModel.role"
                        placeholder="Select your role"
                        filterable
                    >
                        <el-option
                            v-for="(roleKey, roleValue) in rolesParsed"
                            :key="roleValue"
                            :label="roleKey"
                            :value="roleValue"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item
                    label="Timezone"
                    :error="hasErrors('timezone')"
                >
                    <el-select
                        id="timezone-select"
                        v-model="registerModel.timezone"
                        placeholder="Select your timezone"
                        filterable
                    >
                        <el-option-group
                            v-for="(options, group) in timezonesParsed"
                            :key="group"
                            class="timezone"
                            :label="group"
                        >
                            <el-option
                                v-for="(timezoneText, timezoneValue) in options"
                                :key="timezoneValue"
                                :label="timezoneText"
                                :value="timezoneValue"
                            />
                        </el-option-group>
                    </el-select>
                </el-form-item>

                <el-form-item :error="hasErrors('region')">
                    <el-select
                        v-if="isRegionSpecificZone"
                        id="region-select"
                        v-model="registerModel.region"
                        placeholder="Select your region"
                        filterable
                    >
                        <el-option
                            v-for="(regionLabel, regionValue) in regionsParsed"
                            :key="regionValue"
                            class="region"
                            :label="regionLabel"
                            :value="regionValue"
                        />
                    </el-select>
                </el-form-item>

                <div :class="{'has-error': showError('terms'), 'register-terms': true, 'is-error': true}">
                    <el-checkbox
                        v-model="registerModel.terms"
                        class="control-label"
                        :error="hasErrors('terms')"
                    >
                        I agree to the <a
                            :href="lang.trans('external_urls.web_terms_and_conditions')"
                            target="_blank"
                        >Terms of Use</a>
                    </el-checkbox>
                    <div
                        v-if="showError('terms')"
                        class="has-error help-block"
                    >
                        {{ hasErrors('terms') }}
                    </div>
                </div>

                <el-form-item ref="step-register-btn-register-submit">
                    <el-button
                        type="success"
                        class="full-width"
                        name="Register"
                        :loading="isLoading"
                        @click="regularSignUp"
                    >
                        Register
                    </el-button>
                </el-form-item>

                <el-form-item ref="step-register-btn-go-back">
                    <el-button
                        type="text"
                        class="full-width btn btn-link back-to-login-link"
                        name="Cancel"
                        icon="el-icon-arrow-left el-icon-right"
                        @click="goBack"
                    >
                        Cancel
                    </el-button>
                </el-form-item>
            </template>

            <!-- SSO_SIGNUP Only: Section only rendered when we need to redirect the user for an SSO sign-up -->
            <template
                v-else-if="authStep === steps.STEP_SSO_AUTH"
            >
                <p
                    ref="note-paragraph"
                    style="text-align: center; text-justify: distribute-all-lines"
                >
                    AS this email is associated with {{ ssoIdpModel.name }},
                    you will need to log in via {{ ssoIdpModel.name }}'s intranet portal.
                </p>

                <el-form-item ref="btn-sso-portal-redirect">
                    <a
                        class="el-button el-button--success full-width"
                        :href="ssoIdpModel.authnRequestUrl"
                    ><b>{{ ssoIdpModel.name }}</b> portal
                    </a>
                </el-form-item>

                <el-form-item ref="btn-go-back">
                    <el-button
                        type="text"
                        class="full-width"
                        name="Cancel"
                        icon="el-icon-arrow-left el-icon-right"
                        @click="goBack"
                    >
                        Go back
                    </el-button>
                </el-form-item>
            </template>

            <el-form-item
                v-if="!isSso"
                style="text-align: center"
            >
                <a :href="ssoUrl">
                    <el-button
                        class="btn xero-sign-in-button"
                        name="Sign Up with Xero"
                    >
                        <img
                            src="/img/xero_logo.png"
                            class="xero-image inline"
                            alt="Xero Icon"
                        >
                        Sign Up with Xero
                    </el-button>
                </a>
            </el-form-item>

            <el-form-item ref="step-register-btn-go-back-to-login">
                <a
                    :href="loginUrl"
                    class="btn btn-link back-to-login-link"
                ><i class="el-icon-arrow-left" /> Back to Login</a>
            </el-form-item>
        </el-form>

        <invisible-captcha
            ref="captcha"
            :public-key="publicKey"
            :show-captcha="parseJson(showCaptcha)"
            :has-error="hasCaptchaError"
            @verified="onCaptchaVerified"
            @closed="onCaptchaClosed"
        />
    </div>
</template>

<script>
import Register from "Assets/Modules/models/Register";
import PasswordRevealInput from "./PasswordRevealInput.vue";
import {routes, http} from "Figured/Assets/Modules";
import InvisibleCaptcha from "./InvisibleCaptcha";
import AuthUserInterfaceMixin from "Figured/Assets/Mixins/AuthUserInterfaceMixin";

export default {

    components: {
        InvisibleCaptcha,
        PasswordRevealInput
    },

    mixins: [AuthUserInterfaceMixin],

    props: {
        roles: {
            type: String,
            required: true
        },
        timezones: {
            type: String,
            required: true
        },
        regions: {
            type: String,
            required: true
        },
        publicKey: {
            type: String,
            required: true
        },
        showCaptcha: {
            type: String,
            required: true
        },
        ssoUrl: {
            type: String,
            required: true,
        },
        isSso: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            registerModel: new Register(),
            regionSpecificZone: ["Pacific/Auckland"],
            hasCaptchaError: false,
        }
    },

    computed: {
        loginUrl() {
            return routes.get("login");
        },

        isRegionSpecificZone() {
            return _.includes(this.regionSpecificZone, this.registerModel.timezone);
        },

        timezonesParsed() {
            return this.parseJson(this.timezones);
        },

        regionsParsed() {
            return this.parseJson(this.regions);
        },

        rolesParsed() {
            return this.parseJson(this.roles);
        },

        validEmail() {
            return this.validateEmail(this.registerModel.email);
        },
    },

    methods: {
        parseJson(json) {
            return JSON.parse(json);
        },

        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        regularSignUp() {
            // Start Captcha verification
            this.$refs.captcha.start();
            this.isLoading = true;
        },

        updatePassword(password) {
            this.registerModel.password = password;
        },

        onCaptchaVerified(captchaToken) {
            const registerRoute             = routes.get("registration");
            this.registerModel.captchaToken = captchaToken;

            http.post(registerRoute, this.registerModel)
                .then((data) => {
                    this.hasCaptchaError = false;
                    return data;
                })
                .then(this.getVerificationUri)
                .then(routes.get)
                .then(url => window.location.href = url)
                .catch(error => {
                    if (Object.prototype.hasOwnProperty.call(error.response.data, "captchaToken")) {
                        this.hasCaptchaError = true;
                    } else {
                        this.hasCaptchaError = false;
                    }

                    this.onValidationErrors(error);
                })
                .finally(() => this.isLoading = false)
            ;
        },

        getVerificationUri({ status }) {
            return status === 200
                ? "registration.verification"
                : Promise.reject(new Error("failed to get redirect uri"))
            ;
        },

        onCaptchaClosed() {
            this.isLoading = false;
        }
    },

    template: "#register-form"
};
</script>
