<template>
    <div>
        <el-form
            ref="model"
            :model="model"
            label-position="top"
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <el-alert
                    v-if="showAlert"
                    :type="alertType"
                    :title="alertTitle"
                    :description="alertDescription"
                    :closable="false"
                    show-icon
                />
            </el-form-item>

            <el-form-item
                label="Email"
                :error="hasErrors('email')"
            >
                <el-input
                    id="email"
                    v-model="model.email"
                    placeholder="Email"
                    type="email"
                    disabled="disabled"
                    name="email"
                />
            </el-form-item>

            <el-form-item
                label="New Password"
                :error="hasErrors('password')"
            >
                <password-reveal-input
                    v-on:input="updatePassword($event, false)"
                />
            </el-form-item>

            <el-form-item
                label="Confirm New Password"
                :error="hasErrors('password_confirmation')"
            >
                <password-reveal-input
                    id="password-confirmation"
                    name="password_confirmation"
                    v-on:input="updatePassword($event, true)"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    class="full-width padding"
                    type="primary"
                    name="Reset"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Reset Password
                </el-button>
            </el-form-item>

            <el-form-item>
                <a
                    :href="loginUrl"
                    class="btn btn-link back-to-login-link"
                ><i class="el-icon-arrow-left" /> Back to Login</a>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Reset from "Assets/Modules/models/Reset";
import PasswordRevealInput from "./PasswordRevealInput.vue";
import {routes, http} from "Figured/Assets/Modules";

export default {
    template: "#reset-password",

    components: {
        PasswordRevealInput
    },

    props: ["resetToken"],

    data() {
        return {
            model: new Reset({ email: emailToReset }),
            isLoading: false,
            showAlert: false,
            alertType: "error",
            alertTitle: "Error resetting your password",
            alertDescription: "Please contact Figured Support if this issue persists.",
        }
    },

    computed: {
        loginUrl() {
            return routes.get("login");
        },
    },

    mounted() {
        this.model.token = this.resetToken;
    },

    methods: {
        updatePassword(password, isConfirm) {
            if (isConfirm) {
                this.model.password_confirmation = password;
            } else {
                this.model.password = password;
            }

        },

        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        hasErrors: function (inputName) {
            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onSubmit() {
            this.isLoading = true;

            const resetRoute = routes.get("password.reset.save");

            http.post(resetRoute, this.model)
                .then(response => {
                    this.formErrors = {};
                    this.isLoading = false;

                    if (response.status === 200 && response.data[0] === "success") {
                        this.showAlert = true;
                        this.alertType = "success";
                        this.alertTitle = response.data[1];

                        // if user has 2fa enabled redirect to 2fa check
                        this.alertDescription = response.data[2]
                            ? "You will now be automatically redirected to two-factor authentication in 3 seconds."
                            : "You will now be automatically redirected to Figured in 3 seconds.";

                        const redirectRoute = response.data[2]
                            ? routes.get("two_factor_authentication.check.show")
                            : "/";

                        setTimeout(() => {
                            location.replace(redirectRoute);
                        }, 3000);
                    } else if (response.status !== 200) {
                        this.showAlert = true;
                    } else {
                        this.showAlert = true;
                        this.alertDescription = response.data[1];
                    }
                })
                .catch((error) => {
                    this.formErrors = error.response.data;
                    this.isLoading = false;
                });
        }
    }
}
</script>
