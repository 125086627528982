<template>
    <div>
        <el-form
            ref="model"
            :model="model"
            label-position="top"
            @submit.native.prevent
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <el-alert
                    v-if="showAlert"
                    :type="alertType"
                    :title="alertTitle"
                    :description="alertDescription"
                    :closable="false"
                    show-icon
                />
            </el-form-item>

            <el-form-item
                label="Email"
                :error="hasErrors('email')"
            >
                <el-input
                    id="email"
                    v-model="model.email"
                    placeholder="Email"
                    type="email"
                    name="email"
                    disabled="disabled"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    class="full-width padding"
                    type="primary"
                    name="Reset"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Send Password Reset
                </el-button>
            </el-form-item>

            <el-form-item>
                <a
                    href="/login"
                    class="btn btn-link back-to-login-link"
                ><i class="el-icon-arrow-left" /> Back to Login</a>
            </el-form-item>
        </el-form>

        <invisible-captcha
            ref="captcha"
            :public-key="publicKey"
            :show-captcha="parseJson(showCaptcha)"
            :has-error="hasCaptchaError"
            @verified="onCaptchaVerified"
            @closed="onCaptchaClosed"
        />
    </div>
</template>

<script>
import {routes, http} from "Figured/Assets/Modules";
import Forgot from "Assets/Modules/models/Forgot";
import InvisibleCaptcha from "./InvisibleCaptcha";

export default {

    components: {
        InvisibleCaptcha
    },

    props: {
        publicKey: {
            type: String,
            required: true
        },
        showCaptcha: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            model: new Forgot({email: postBackEmail}),
            formErrors: {},
            showAlert: false,
            alertType: "error",
            alertTitle: "Error resetting your password",
            alertDescription: "Please contact Figured Support if this issue persists.",
            isLoading: false,
            hasCaptchaError: false,
        }
    },

    methods: {
        parseJson(json) {
            return JSON.parse(json);
        },

        onSubmit() {
            this.$refs.captcha.start();
            this.isLoading = true;
        },

        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        hasErrors: function (inputName) {
            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onCaptchaVerified(captchaToken) {
            const resetRoute        = routes.get("password.forgot");
            this.model.captchaToken = captchaToken;

            http.post(resetRoute, this.model)
                .then(response => {
                    this.hasCaptchaError = false;
                    this.formErrors      = {};
                    this.isLoading       = false;

                    if (response.status === 200 && response.data[0] === "success") {
                        this.showAlert = true;
                        this.alertType = "success";
                        this.alertTitle = response.data[1];
                        this.alertDescription = "Please check your email for further instructions.";
                    } else if (response.status !== 200) {
                        this.showAlert = true;
                    } else {
                        this.showAlert = true;
                        this.alertDescription = response.data[1];
                    }
                })
                .catch((error) => {
                    if (Object.prototype.hasOwnProperty.call(error.response.data, "captchaToken")) {
                        this.hasCaptchaError = true;
                    } else {
                        this.hasCaptchaError = false;
                    }

                    this.formErrors = error.response.data;
                    this.isLoading = false;
                });
        },

        onCaptchaClosed() {
            this.isLoading = false;
        }
    },
    template: "#forgot-password"
}
</script>
