<template id="login-form">
    <div>
        <el-form
            ref="loginModel"
            :model="loginModel"
            nonvalidate="true"
            label-position="top"
            @submit="onSubmit(loginModel.email, regularLogin)"
            @submit.native.prevent
            @keyup.enter.native="onSubmit(loginModel.email, regularLogin)"
        >
            <!-- All these sections will be rendered upon state behaviour changes
             depending on the data we have on the user trying to log in -->

            <!-- EMAIL_LOGIN_ONLY: Section only rendered at first when requesting for a valid email -->
            <template
                v-if="authStep === steps.STEP_EMAIL"
            >
                <el-form-item label="Email">
                    <el-input
                        id="email"
                        v-model="loginModel.email"
                        placeholder="Email"
                        :error="hasErrors('email')"
                        type="email"
                        name="email"
                        autofocus
                    />
                </el-form-item>

                <el-form-item>
                    <el-button
                        class="full-width"
                        type="primary"
                        :disabled="!validEmail"
                        name="Next"
                        :loading="isLoading"
                        @click="next(loginModel.email)"
                    >
                        Next
                    </el-button>
                </el-form-item>

                <el-form-item
                    v-if="!xeroSsoKey"
                    style="text-align: center"
                >
                    <a :href="ssoUrl">
                        <el-button
                            class="btn xero-sign-in-button"
                            name="Sign In with Xero"
                        >
                            <img
                                src="/img/xero_logo.png"
                                class="xero-image inline"
                                alt="Xero Icon"
                            >
                            Sign In with Xero
                        </el-button>
                    </a>
                </el-form-item>


                <el-form-item>
                    <div class="register-now text-center">
                        Don't have an account? <a :href="registerUrl">Register now</a>
                    </div>
                </el-form-item>
            </template>

            <!-- REGULAR_LOGIN Only: Section only rendered when a regular/normal signup form is required -->
            <template
                v-else-if="authStep === steps.STEP_REGULAR_AUTH"
            >
                <el-form-item
                    label="Email"
                    :error="hasErrors('email')"
                >
                    <el-input
                        id="email"
                        v-model="loginModel.email"
                        placeholder="Email"
                        disabled="disabled"
                        type="email"
                        name="email"
                    />
                </el-form-item>

                <el-form-item
                    label="Password"
                    :error="hasErrors('password')"
                >
                    <password-reveal-input v-on:input="updatePassword" />
                </el-form-item>

                <div class="login-form-controls">
                    <el-checkbox
                        v-model="rememberMe"
                        class="control-label"
                    >
                        Trust this device
                    </el-checkbox>

                    <a
                        :href="forgotPasswordUrl"
                        class="btn btn-link reset-link"
                    >Reset your password</a>
                </div>


                <el-form-item>
                    <el-button
                        class="full-width"
                        type="primary"
                        name="regular-login-submit"
                        :loading="isLoading"
                        @click="regularLogin"
                    >
                        Log in
                    </el-button>
                </el-form-item>


                <el-form-item>
                    <el-button
                        type="text"
                        class="full-width"
                        name="Cancel"
                        icon="el-icon-arrow-left el-icon-right"
                        @click="goBack"
                    >
                        Cancel
                    </el-button>
                </el-form-item>


                <el-form-item>
                    <div class="register-now text-center">
                        Don't have an account? <a :href="registerUrl">Register now</a>
                    </div>
                </el-form-item>
            </template>

            <!-- SSO_LOGIN Only: Section only rendered when we need to redirect the user for an SSO sign-up -->
            <template
                v-else-if="authStep === steps.STEP_SSO_AUTH"
            >
                <p style="text-align: center; text-justify: distribute-all-lines">
                    As this email is associated with {{ ssoIdpModel.name }},
                    you will need to log in via the {{ ssoIdpModel.name }} intranet portal.
                </p>

                <el-form-item>
                    <a
                        class="el-button el-button--primary full-width"
                        :href="ssoIdpModel.authnRequestUrl"
                    ><b>{{ ssoIdpModel.name }}</b> Log in
                    </a>
                </el-form-item>

                <el-form-item>
                    <el-button
                        type="text"
                        class="full-width"
                        name="Cancel"
                        icon="el-icon-arrow-left el-icon-right"
                        @click="goBack"
                    >
                        Back
                    </el-button>
                </el-form-item>
            </template>
        </el-form>
    </div>
</template>

<script>
import {http, routes} from "Figured/Assets/Modules";
import PasswordRevealInput from "./PasswordRevealInput.vue";
import Login from "Assets/Modules/models/Login";
import AuthUserInterfaceMixin from "Figured/Assets/Mixins/AuthUserInterfaceMixin";


export default {

    template: "#login-form",

    mixins: [AuthUserInterfaceMixin],

    components: {
        PasswordRevealInput,
    },

    data() {
        return {
            loginModel: new Login(),
            rememberMe: false,
        }
    },

    props: {
        xeroSsoKey: {
            type: String,
            required: false,
        },
        ssoUrl: {
            type: String,
            required: true,
        }
    },

    computed: {
        registerUrl() {
            return routes.get("registration");
        },

        forgotPasswordUrl() {
            return routes.get("password.request",  {}, { email: this.loginModel.email });
        },

        validEmail() {
            return this.validateEmail(this.loginModel.email);
        },
    },

    methods: {

        updatePassword(password) {
            this.loginModel.password = password;
        },

        regularLogin() {
            if (!this.validEmail) return;
            this.isLoading = true;

            const loginRoute = routes.get("login");

            http.post(loginRoute, this.getLoginPayLoad())
            // if map result is undefined, it will get piped through the catch.
                .then(response => {
                    if (response.data.twoFARoute) {
                        window.location.href = routes.get(response.data.twoFARoute, {}, {"ssoKey": response.data.ssoSignupKey}, true);

                        return;
                    }

                    if (!response.data.location) {
                        // probably a 302 redirect, due to credentials in cookie session already active
                        window.location.reload();
                    }

                    window.location.href = response.data.location;
                })
                .catch(this.onValidationErrors)
                .finally(() => this.isLoading = false)
            ;
        },

        getLoginPayLoad() {
            let formData = this.loginModel.getFormData();
            if (this.xeroSsoKey) {
                return {
                    ...formData,
                    "sso_signup_key": this.xeroSsoKey,
                }
            }
            return formData;
        }
    },

    watch: {
        rememberMe(flag) {
            this.loginModel.remember = flag;
            this.ssoIdpModel.remember = flag;
        },
    }
};
</script>
