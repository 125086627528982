
<template id="password-reveal-input">
    <div :class="containerClass">
        <el-input
            :id="id"
            v-model="inputValue"
            :type="inputType"
            :name="name"
            :disabled="disabled"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            @input="onEmitChange"
        />
        <span
            :class="passwordToggleClasses"
            @click="togglePassword"
        />
    </div>
</template>

<script>

const TYPE_PASSWORD = "password";
const TYPE_TEXT = "text";

export default {
    name: "PasswordRevealInput",

    props: {
        name: {
            type: String,
            default: "password",
        },
        containerClass: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "Password",
        },
        autocomplete: {
            type: String,
            default: "on",
        },
        id: {
            type: String,
            default: "password",
        }
    },

    data: function () {
        return {
            inputValue: "",
            inputType: TYPE_PASSWORD,
        };
    },

    computed: {
        shouldNotShowPassword() {
            return this.inputType === TYPE_PASSWORD;
        },
        passwordToggleClasses() {
            return {
                "far": true,
                "fa-fw": true,
                "fa-eye": this.shouldNotShowPassword,
                "fa-eye-slash": !this.shouldNotShowPassword,
                "field-icon": true,
                "toggle-password": true,
                "form-controle": true,
            };
        },
    },

    methods: {
        togglePassword() {
            this.inputType = this.shouldNotShowPassword ? TYPE_TEXT : TYPE_PASSWORD;
        },
        onEmitChange() {
            this.$emit("input", this.inputValue);
        },
    },

    template: "#password-reveal-input"
}

</script>

<style scoped>

    .toggle-password {
        float: right;
        margin-top: -2.5rem;
        padding-right: 3rem;
        position: relative;
        z-index: 2;
        color: #607d8b;
        cursor: pointer;
    }

</style>
