<template>
    <div>
        <el-form
            id="register-form"
            ref="model"
            :model="model"
            label-position="top"
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <p class="lead text-center">
                    <b>Welcome back!</b>
                </p>
                <p class="text-center">
                    Log in to accept your invite to collaborate on this farm.
                </p>
            </el-form-item>

            <el-form-item>
                <el-alert
                    v-if="showAlert"
                    type="error"
                    title="Error Signing In"
                    :description="alertDescription"
                    :closable="false"
                    show-icon
                />
            </el-form-item>

            <el-form-item
                label="Email"
                :error="hasErrors('email')"
            >
                <el-input
                    id="email"
                    :value="userEmail"
                    placeholder="Email"
                    type="email"
                    name="email"
                    class="disabled-email"
                    :disabled="true"
                />
            </el-form-item>

            <el-form-item
                label="Password"
                :error="hasErrors('password')"
            >
                <password-reveal-input v-on:input="updatePassword" />
            </el-form-item>

            <div class="login-form-controls">
                <a
                    :href="forgotPasswordUrl"
                    class="btn btn-link reset-link"
                >Reset your password</a>
            </div>

            <el-form-item>
                <el-button
                    class="full-width"
                    type="primary"
                    name="Log in"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Log in
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Login from "Assets/Modules/models/Login";
import PasswordRevealInput from "./PasswordRevealInput.vue";
import {routes, http} from "Figured/Assets/Modules";

export default {
    template: "#invite-login",

    components: {
        PasswordRevealInput
    },

    props: {
        userEmail: {
            type: String,
            required: true
        },
        inviteHash: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            model: new Login(),
            formErrors: {},
            isLoading: false,
            alertDescription: "",
            showAlert: false,
        }
    },

    computed: {

        forgotPasswordUrl() {
            return routes.get("password.request", {}, { email: this.userEmail });
        }
    },

    methods: {
        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        hasErrors: function (inputName) {
            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onSubmit() {
            this.isLoading = true;
            const loginRoute = routes.get("collaborate.invite.accept", {"inviteHash": this.inviteHash});
            this.model.email = this.userEmail;

            http.post(loginRoute, this.model)
                .then(response => {
                    if (response.status === 200 && response.data[0] === "success") {
                        console.log(response);
                        location.replace("/invite/accept/" + response.data[1]);
                        return;
                    } else {
                        this.showAlert = true;
                        this.alertDescription = response.data[1];
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    this.formErrors = error.response.data;
                    this.isLoading = false;
                });
        },

        updatePassword(password) {
            this.model.password = password;
        },
    }
}
</script>