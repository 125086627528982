<template>
    <div>
        <vue-recaptcha
            ref="invisibleRecaptcha"
            size="invisible"
            badge="inline"
            :sitekey="publicKey"
            @verify="onVerify"
        />

        <el-alert
            v-if="hasError"
            title="reCAPTCHA Error"
            class="captchaError"
            type="error"
            description="An issue has occurred verifying reCAPTCHA. Please contact Figured Support if this issue persists."
            :closable="false"
            show-icon
        />

        <div class="captchaMessage">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
    </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import {routes, http} from "Figured/Assets/Modules";

export default {
    template: "#invisible-captcha",

    components: {
        VueRecaptcha
    },

    props: {
        publicKey: {
            type: String,
            required: true,
        },
        showCaptcha: {
            type: Boolean,
            required: true,
        },
        hasError: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        resetCaptcha() {
            this.$refs.invisibleRecaptcha.reset();
        },

        start() {
            if (this.showCaptcha) {
                this.$refs.invisibleRecaptcha.execute();
            } else {
                this.$emit("verified");
            }

            // When clicking on the whitespace around the ReCaptcha challenge (to close it), emit the closed event
            const challengeWhitespace = $("iframe[title='recaptcha challenge']").parent().prev();
            challengeWhitespace.click(() => {
                this.$emit("closed");
            });
        },

        // Captcha was successfully completed
        onVerify(captchaToken) {
            this.$emit("verified", captchaToken);
            this.resetCaptcha();
        }
    },

}
</script>
