<template>
    <div>
        <el-form
            id="register-form"
            ref="model"
            :model="model"
            label-position="top"
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <p class="login-description text-center">
                    <strong>Email address verified!</strong>
                </p>
                <p class="login-description text-center">
                    Your email address domain matches several {{ lang.trans('terminology.organisation') }}s that already exist in Figured.
                </p>
                <p class="login-description text-center">
                    Select the {{ lang.trans('terminology.organisation') }} you are most closely associated with.
                </p>
            </el-form-item>

            <el-form-item
                label="Organisation"
                :error="hasErrors('practice')"
            >
                <el-select
                    id="practice"
                    v-model="model.practice"
                    filterable
                >
                    <el-option
                        v-for="(roleKey, roleValue) in practices"
                        :key="roleValue"
                        :label="roleKey"
                        :value="roleValue"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button
                    class="full-width"
                    type="primary"
                    name="Register"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Save & Continue
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {routes, http} from "Figured/Assets/Modules";
import PracticeSelect from "Assets/Modules/models/PracticeSelect";

export default {
    template: "#practice-select",

    data() {
        return {
            model: new PracticeSelect(),
            isLoading: false,
            formErrors: {},
        }
    },

    props: {
        practices: {
            type: Object,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
    },

    methods: {
        showError(inputName) {
            return !_.isEmpty(this.hasErrors(inputName));
        },

        hasErrors: function (inputName) {
            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onSubmit() {
            const registerRoute = routes.get("registration.confirm", {"token": this.token});

            http.post(registerRoute, this.model)
                .then(response => {
                    if (response.status === 200) {
                        location.replace(routes.get("two_factor_authentication.setup.show", {}, {redirect: 1}));
                        return;
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    this.formErrors = error.response.data;
                    this.isLoading = false;
                });
        }
    }
}
</script>